.display__container {
  height: 280px;
  width: 100%;
  background-color: #9d8c85;
  background-color: white;
  border-radius: 10px;
  box-shadow: inset 0px 0px 4px black;
}

@media (max-width: 550px) {
  .display__container {
    // height: 65vw;
  }
}

@media (max-height: 750px) and (min-width: 440px) {
  .display__container {
    height: 100%;
  }
}

@media (max-height: 750px) and (max-width: 440px) {
  .display__container {
    min-height: 87%;
  }
}
