@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

* {
  padding: 0;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  // background-color: #00bad8;
}

// @font-face {
//   font-family: 'FuturaBold';
//   src: local('FuturaBold'), url('./fonts/Futura/Futura\ Bold\ Italic\ font.ttf') format('truetype');
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Invasion';
//   src: local('Invasion'), url('./fonts/INVASION2000.TTF') format('truetype');
// }

@font-face {
  font-family: 'FuturaBold';
  src: url('./fonts/futura_bold_italic_font-webfont.woff2') format('woff2'), url('./fonts/futura_bold_italic_font-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'early_gameboyregular';
  src: url('./fonts/early_gameboy-webfont.woff2') format('woff2'), url('./fonts/early_gameboy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Invasion';
  src: url('./fonts/invasion2000-webfont.woff2') format('woff2'), url('./fonts/invasion2000-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin noSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes pulse2 {
  0% {
    transform: scale(0.97);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.97);
  }
}
