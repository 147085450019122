@mixin triangle {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 24px solid #131313;
  opacity: 0.7;
}

.dPad__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 100%;

  button {
    background-color: #2b2b2b;
    transition: background-color ease 500ms;
    cursor: pointer;
    height: 55px;
    width: 55px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #1a1a1a;
    transition: all 100ms ease;

    &:active {
      box-shadow: none;
    }

    &:active {
      box-shadow: none;
    }
  }

  .middle {
    position: relative;
    // visibility: hidden;

    .horizontal {
      position: absolute;
      background-color: #2b2b2b;
      transition: background-color ease 500ms;
      margin-top: 2px;
      margin-left: 0px;
      height: 51px;
      width: 55px;
      // z-index: -1;
      // @media (max-width: 440px) {
      //   height: 50px;
      //   width: 60px;
      // }
    }

    .vertical {
      position: absolute;
      background-color: #2b2b2b;
      transition: background-color ease 500ms;
      margin-left: 2.5px;
      // margin-top: 5px;
      height: 53px;
      width: 50px;
      z-index: 0;

      // @media (max-width: 440px) {
      //   height: 55px;
      //   width: 45px;
      // }
    }

    .circle {
      position: absolute;
      height: 30px;
      width: 30px;
      background-color: rgba($color: #000000, $alpha: 0.3);
      box-shadow: inset 3px -3px 3px rgba($color: #ffffff, $alpha: 0.13), inset -3px 3px 2px rgba($color: #000000, $alpha: 0.2);
      border-radius: 100px;

      transform: translate(43%, 43%);
    }

    .shadow {
      position: absolute;
      height: 5px;
      width: 5px;
      background-color: #000000;
      opacity: 0.3;
      bottom: -5px;
      left: -5px;
    }
  }

  .up {
    height: 57px;
    margin-bottom: -2px;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: -5px 5px 0px rgba($color: #000000, $alpha: 0.3), inset -2px 2px 2px rgba($color: #ffffff, $alpha: 0.15);

    // &:hover {
    //   filter: brightness(0.8)
    // }

    .triangle {
      @include triangle;
    }
  }

  .left {
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: -5px 5px 0px rgba($color: #000000, $alpha: 0.3), inset 2px -2px 2px rgba($color: #000000, $alpha: 0.4);

    .triangle {
      transform: rotate(270deg);
      @include triangle;
    }
  }

  .right {
    // width: 60px;
    // margin-left: -5px;
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: -5px 5px 0px rgba($color: #000000, $alpha: 0.3), inset -2px 2px 2px rgba($color: #ffffff, $alpha: 0.15);

    .triangle {
      transform: rotate(90deg);
      @include triangle;
    }
  }

  .down {
    height: 57px;
    margin-top: -4px;
    border-top: none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: -5px 9px 0px rgba($color: #000000, $alpha: 0.3), inset 2px -2px 2px rgba($color: #000000, $alpha: 0.4);

    .triangle {
      transform: rotate(180deg);
      @include triangle;
    }
  }
}

@media (max-width: 440px) {
  .dPad__container {
    transform: scale(0.93);
    margin-left: -8px;
  }
}

@media (max-width: 420px) {
  .dPad__container {
    transform: scale(0.85);
    margin-left: -12px;
  }
}

@media (max-width: 400px) {
  .dPad__container {
    transform: scale(0.8);
    margin-left: -18px;
  }
}
