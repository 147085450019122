.gameboyLayout__container {
  width: 100%;
  max-width: 450px;
  height: 100%;
  max-height: 700px;
  @media (max-height: 700px) {
    max-height: 100%;
  }

  @media (max-width: 450px) {
    max-height: 100%;
  }
  border-radius: 30px 30px 100% 100% / 30px 30px 20% 20%;
  box-shadow: inset 10px -10px 50px rgba($color: #000000, $alpha: 0.4), inset -5px 5px 10px rgba($color: #ffffff, $alpha: 0.6), 0 0 800px rgba($color: #000000, $alpha: 0.5);
  border: 3px solid rgba($color: #000000, $alpha: 0.3);
  display: flex;
  flex-direction: column;
  transition: background-color ease 400ms;
}

.gameboyLayout__display {
  height: 100%;
  max-height: 370px;
  margin: 18px 24px;
  margin-bottom: 16px;
  padding: 20px;
  padding-bottom: 64px;
  border: 5px solid #151515;
  background-color: #232323;
  border-radius: 10% 10% 100% 100% / 10% 10% 20% 20%;

  .gameboyLayout__displayTitle {
    font-family: 'FuturaBold';
    font-size: 26px;
    color: rgba($color: #ffffff, $alpha: 0.6);
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 4px;

    .color {
      margin-left: 12px;
      font-family: 'Nanum Pen Script';
      font-weight: 800;
      font-size: 44px;
    }
  }
}

.gameboyLayout__buttons {
  height: 100%;
}

@media (max-width: 450px) {
  .gameboyLayout__container {
    border-radius: 0;
    border: none;
  }
}

@media (max-width: 530px) {
  .gameboyLayout__container {
    .gameboyLayout__display {
      margin: 16px 18px;
    }
  }
}

@media (max-width: 440px) {
  .gameboyLayout__container {
    .gameboyLayout__display {
      margin: 8px 8px;
      padding: 14px;
    }
  }
}

@media (max-height: 750px) {
  .gameboyLayout__container {
    .gameboyLayout__display {
      // max-height: 51vh;
    }
  }
}

@media (max-width: 340px) {
  .gameboyLayout__display {
    .gameboyLayout__displayTitle {
      font-size: 7vw;

      .color {
        font-size: 11vw;
      }
    }
  }
}
