.circularButton__container {
  height: 60px;
  width: 60px;
  background-color: #2b2b2b;
  border: 3px solid black;
  border-radius: 100px;
  cursor: pointer;
  font-family: 'Press Start 2P';
  font-size: 18px;
  box-shadow: -6px 6px 0px rgba($color: #000000, $alpha: 0.3), inset -3px 3px 3px rgba($color: #ffffff, $alpha: 0.2), inset 3px -3px 2px rgba($color: #000000, $alpha: 0.5);
  transition: 100ms ease all;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color ease 500ms;

  &:hover {
    // filter: brightness(0.7);
  }

  &:active {
    // filter: brightness(0.5);
    box-shadow: -2px 2px 0px rgba($color: #000000, $alpha: 0.4);
  }

  span {
    margin-top: 0px !important;
    margin-right: 0px !important;
    opacity: 0.5;
  }
}

@media (max-width: 340px) {
  .circularButton__container {
    height: 55px;
    width: 55px;
  }
}
