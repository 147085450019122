.progressBar__container {
  width: 100%;
  height: 36px;
  border: 4px solid rgb(0, 123, 255);
  background-color: white;
  position: relative;
  border-radius: 8px;

  .fill {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 22px;
    border-radius: 2px;
  }
}
