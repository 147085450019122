.app__container {
  height: 100vh;
  width: 100vw;
  background-color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: background-color ease 800ms;
}

#video {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0.8;
}
