.pressStartScreen__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 100%;
  overflow: hidden;

  .logo {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin: -10px 0px;
  }

  .nft__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: flex-end;
    justify-items: center;

    .nft {
      width: 130%;
      height: auto;
      object-fit: contain;
      margin: 0px -18px;

      &:nth-child(1) {
        z-index: 1;
        margin-left: 20%;
      }

      &:nth-child(2) {
        z-index: 2;
        width: 160%;
        margin-right: -40%;
      }

      &:nth-child(3) {
        z-index: 3;
        width: 180%;
        margin-right: -40%;
      }

      &:nth-child(4) {
        z-index: 2;
        width: 160%;
        margin-right: -20%;
      }

      &:nth-child(5) {
        z-index: 1;
        margin-right: 20%;
      }
    }
  }

  .pressStart {
    border: 4px solid black;
    background-color: white;
    width: 100%;
    border-radius: 100px;
    padding: 8px 8px;
    font-family: 'Invasion';
    text-align: center;
    box-shadow: 3px 3px 0px black;
    margin-top: -6px;
    z-index: 6;

    p {
      font-size: 17px;
      animation: pulse 1200ms infinite;
      animation-delay: 2000ms;
    }

    @keyframes pulse {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .copyright {
    font-family: 'Invasion';
    font-size: 12px;
    margin-top: 12px;
  }
}
