@import '../../../index.scss';

.flatButton__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 8px;

  button {
    height: 13px;
    width: 38px;
    background-color: #2b2b2b;
    border: 2px solid black;
    border-radius: 100px;
    box-shadow: -3px 3px 0px rgba($color: #000000, $alpha: 0.3), inset -1.5px 1.5px 2px rgba($color: #ffffff, $alpha: 0.2), inset 2px -2px 1px rgba($color: #000000, $alpha: 0.5);
    transition: 100ms ease all;
    cursor: pointer;

    &:hover {
      // box-shadow: -4px 4px 0px rgba($color: #000000, $alpha: 0.3), ;
      // background-color: #202020;
    }

    &:active {
      // background-color: #131313;
      box-shadow: -2px 2px 0px rgba($color: #000000, $alpha: 0.4);
    }
  }

  p {
    @include noSelect;
    margin: 8px 0px;
    font-family: 'Press Start 2P';
    font-size: 6px;
    opacity: 0.4;
  }
}
